import {
  LOADING, ON_CHANGE_FLIGHT_TYPE,
  ON_FAIL_SEARCH_RECOMMENDATIONS,
  ON_RECEIVE_RECOMMENDATIONS, 
  ON_RECEIVE_NEGATIVE_FARES,
  FILTER_BY_ALL_FILTERS,
  CALL_TO_RENDER_FILTERS,
  ON_CHANGE_EMAIL,
  ON_CHANGE_RUC,
  ON_CHANGE_HAS_RUC,
  ON_FAIL_SEARCH_RECOMMENDATIONS_BY_EMAIL,
  ON_FAIL_SEARCH_RECOMMENDATIONS_BY_RUC,
  ON_CHANGE_HAS_FEE,
  ON_CHANGE_FEE,
  ON_FAIL_SEARCH_RECOMMENDATIONS_BY_FEE,
  ON_FAIL_BY_SERVER_RESPONSE
} from "../types/searchRecommendationsTypes";

import getRecommendationRS from "../transfer/recommendationTransfers/getRecommendationsRS";
import { ALL, ASCENDENT_SORTED_PRICE, FILTER_CLASS_INDEX, FILTER_PRICE_INDEX, FILTER_RATE_INDEX } from "../constants/filterConstants";

const initialState = {
  loading: false,
  recommendations: [],
  recommendationsRS: [],
  flightTypeOption: "RT",
  recommendationsInitial: [],
  isCalledToRenderFilters: false,
  email: "",
  ruc: "",
  feeAmount: 0,
  hasRUC: false,
  hasFEE: false
};

const filterRecommendationsByAllFilters = (recommendations = [], filters = []) => {
  filters.forEach(filter => {
    recommendations = getFilterRecommendationByFilter(filter, recommendations);
  })
  return recommendations;
}

const getFilterRecommendationByFilter = (filter, recommendations) => {
  switch (filter.index) {
    case FILTER_CLASS_INDEX:
      return filterRecommendationsByBookingClass(recommendations, filter.value);
    case FILTER_PRICE_INDEX:
      return filterRecommendationsBySortedPrice(recommendations, filter.value);
    case FILTER_RATE_INDEX:
      return filterRecommendationsByRate(recommendations, filter.value);
    default:
      return recommendations;
  }
}

const filterRecommendationsByBookingClass = (recommendations, bookingClass) => {

  if (bookingClass === ALL) {
    return recommendations;
  }
  return recommendations.filter(({
    itinerary
  }) => {
    let flag = false;
    for (let option of itinerary) {
        if (option.flights[0].segments[0].bookingClass === bookingClass) {
          flag = true;
          break;
        }
    }
    return flag;
  });
}

const filterRecommendationsBySortedPrice = (recommendations, sortedPrice) => {
  if(sortedPrice === ASCENDENT_SORTED_PRICE) {
    const recommendationsSorted = ascendentSortedAlghoritm(recommendations);
    return recommendationsSorted.filter( r => r.id !== null );
  } else {
    const recommendationsSorted = descendentSortedAlghoritm(recommendations);
    return recommendationsSorted.filter( r => r.id !== null );
  }
}

const filterRecommendationsByRate = (recommendations, rate) => {
  //console.log('filterRecommendationsByRate');
  //console.log('recommendations -> ', recommendations);
  //console.log('rate -> ', rate);
  if (rate === ALL) {
    return recommendations;
  }
  return recommendations.filter(({
    itinerary
  }) => {
    let flag = false;
    for (let option of itinerary) {
      console.log('option dentro del foreach -> ', option);
      for (let flight of option.flights) {
        if (flight.brandedFare.description === rate) {
          flag = true;
          break;
        }
      }
    }
    return flag;
  });
}

const ascendentSortedAlghoritm = (recommendations) => {
  return recommendations.sort((a, b) => {
    if(parseFloat(a.pricing.finalItem.amountText.slice(4)) 
          > parseFloat(b.pricing.finalItem.amountText.slice(4))) {
        return 1;
    } else if(parseFloat(a.pricing.finalItem.amountText.slice(4)) 
        < parseFloat(b.pricing.finalItem.amountText.slice(4))) {
        return -1;
    }
    return 0;
    });
}

const descendentSortedAlghoritm = (recommendations) => {
  return recommendations.sort((a, b) => {
    if(parseFloat(a.pricing.finalItem.amountText.slice(4)) 
          > parseFloat(b.pricing.finalItem.amountText.slice(4))) {
        return -1;
    } else if(parseFloat(a.pricing.finalItem.amountText.slice(4)) 
        < parseFloat(b.pricing.finalItem.amountText.slice(4))) {
        return 1;
    }
    return 0;
    });
}

export default function(state = initialState, action) {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: true, errorMsg: "", errorMsgNegativeFare: "" };
    case ON_RECEIVE_RECOMMENDATIONS:
      const recommendations =  getRecommendationRS(action.payload);
      return {
        ...state,
        loading: false,
        isComplete: true,
        recommendations: recommendations,
        recommendationsInitial: recommendations,
        recommendationsRS: action.payload,
        errorMsgNegativeFare: null,
        errorMsg: null
      };
    case ON_RECEIVE_NEGATIVE_FARES:
      return {
        ...state,
        loading: false,
        isComplete: false,
        errorMsgNegativeFare: "Todas la recomendaciones tienen tarifas negativas",
        recommendations: [],
        recommendationsRS: []
      };
    case ON_FAIL_SEARCH_RECOMMENDATIONS:
      return {
        ...state,
        loading: false,
        isComplete: false,
        errorMsg: "Error interno. Por favor comunicarse con el equipo de TI.",
        recommendations: [],
        recommendationsRS: []
      };
    case ON_CHANGE_FLIGHT_TYPE:
      return {
        ...state,
        flightTypeOption: action.payload
      };
    case FILTER_BY_ALL_FILTERS:
      const recommendationsFiltered = filterRecommendationsByAllFilters(state.recommendationsInitial, action.payload);
      return {
        ...state,
        recommendations: recommendationsFiltered
      };
    case CALL_TO_RENDER_FILTERS:
      return {
        ...state,
        isCalledToRenderFilters: action.payload
      }
    case ON_CHANGE_EMAIL:
      return {
        ...state,
        email: action.payload
      }
    case ON_CHANGE_RUC:
      return {
        ...state,
        ruc: action.payload
      }
    case ON_CHANGE_HAS_RUC:
      return {
        ...state,
        hasRUC: action.payload
      }
    case ON_CHANGE_HAS_FEE:
    return {
      ...state,
      hasFEE: action.payload
    }
    case ON_CHANGE_FEE:
      return {
        ...state,
        feeAmount: action.payload
      }
    case ON_FAIL_SEARCH_RECOMMENDATIONS_BY_EMAIL:
      return {
        ...state,
        loading: false,
        isComplete: false,
        errorMsg: "Por favor ingrese el campo email para continuar con la búsqueda.",
        recommendations: [],
        recommendationsRS: []
      };
    case ON_FAIL_SEARCH_RECOMMENDATIONS_BY_RUC:
      return {
        ...state,
        loading: false,
        isComplete: false,
        errorMsg: "Por favor ingrese el campo RUC para continuar con la búsqueda.",
        recommendations: [],
        recommendationsRS: []
      };
    case ON_FAIL_SEARCH_RECOMMENDATIONS_BY_FEE:
      return {
        ...state,
        loading: false,
        isComplete: false,
        errorMsg: "Por favor ingrese el campo FEE para continuar con la búsqueda.",
        recommendations: [],
        recommendationsRS: []
      };
    case ON_FAIL_BY_SERVER_RESPONSE:
      return {
        ...state,
        loading: false,
        isComplete: false,
        errorMsg: "El servicio ha retornado error en su respuesta. Por favor comunicarse con el equipo de TI",
        recommendations: [],
        recommendationsRS: []
      }
    default:
      return state;
  }
}
