import config from '../../config';

const getBookModifyRQ = (params,travelItineraryRS, recommendation, flightTypeOption) => {

    //console.log("recommendation -->", recommendation);
    //console.log("params -->", params);
    //console.log("travelItineraryRS -->", travelItineraryRS);

    let tickets = [];
    let offerId = recommendation.offerId.offerId;
    let shoppingResponseRefID = recommendation.shoppingResponseRefID.shoppingResponseRefID;
    let brandCode = "";
    if(recommendation.itinerary[0].flights[0].brandedFare){
        brandCode = recommendation.itinerary[0].flights[0].brandedFare.brandID;
    }
    const ticketingVendorCode = recommendation.itinerary[0].flights[0].airline.code;
    travelItineraryRS.ticketing.forEach(t => {

        // travelItineraryRS.travelerInfo.airTraveler.forEach(a =>{
        //     if(t.travelerRefNumber == a.travelerRefNumber.rph) {
        //         if(a.passengerTypeCode != "INF"){
            const ticket = {
                "ticketAdvisory": [],
                "ticketingVendor": {
                    "code": ticketingVendorCode
                },
                "flightSegmentRefNumber": [],
                "travelerRefNumber": t.travelerRefNumber,
                "ticketDocumentNbr": t.ticketDocumentNbr,
                "miscTicketingCode": [],
                "tpaextensions" : {
                    "any" : [
                        "<?xml version='1.0' encoding='UTF - 16'?><brandCode>"+brandCode+"</brandCode>"
                    ]
                }
    
            };
            tickets.push(ticket);
        //         }
        //     }
        // });
    });
    let aplicaFee = true;
    let currentUser = "";
    let accountId = "";
    const sessionJson = sessionStorage.getItem(config.app);

    if (sessionJson) {
        const session = JSON.parse(sessionJson);
        if(session){
            if (session.signature) {
                const signature = Buffer.from(session.signature, 'base64').toString('ascii');
                aplicaFee = (signature !== 'AGY');
            }
            
            if(session.currentUser){
                currentUser = Buffer.from(session.currentUser, 'base64').toString('ascii');
            }

            if(session.accountId){
                accountId = Buffer.from(session.accountId, 'base64').toString('ascii');
            }
        }
    }
    let totalAmount = recommendation.pricing.finalItem.amountText.replace('US$ ', '');
    let baseAmount = recommendation.pricing.items[0].amountText.replace('US$ ', '');
    let taxesAmount = recommendation.pricing.items[1].amountText.replace('US$ ', '');
    let penality = (aplicaFee) ? recommendation.pricing.items[2].amountText.replace('US$ ', '') : 0;

    let originDestinationOptions = [];
    recommendation.itinerary.forEach(itinerary => {
        let flightSegment = [];
        itinerary.flights[0].segments.forEach(f => {
            const flight = {
                "departureAirport": {
                    "locationCode": f.departureAirport.code
                },
                "arrivalAirport": {
                    "locationCode": f.arrivalAirport.code
                },
                "equipment": [],
                "departureDateTime": f.departureDateTime,
                "arrivalDateTime": f.arrivalDateTime,
                "marketingAirline": f.marketingAirline,
                "flightNumber": f.flightNumber,
                "resBookDesigCode": f.bookingClass,//PREGUNTAR QUE ES
                "bookingClassAvails": [],
                "comment": [],
                "stopLocation": []
            };
            flightSegment.push(flight);
        });
        let travelPurpose = "";
        if (flightTypeOption === "RT") {
            travelPurpose="CHANGE";
        } else {
            if (itinerary.indicator === 0 && flightTypeOption === "OW") {
                travelPurpose="CHANGE";
            }
            if (itinerary.indicator === 1 && flightTypeOption === "OR") {
                travelPurpose="CHANGE";
            }
        }
        const originDestinationOpt = {
            "flightSegment": flightSegment,
            "travelPurpose": travelPurpose
        };
        originDestinationOptions.push(originDestinationOpt);
    });

    let emdInfo = [];

    if (params.formOfPayment === "CC") {
        const paymentDetails = {
            "paymentDetail": [
                {
                    "paymentCard": {
                        "tpaextensions" : {
                            "any" : [
                                "<?xml version='1.0' encoding='UTF - 16'?><number>"+params.paymentDetails.number+"</number>",
                                "<?xml version='1.0' encoding='UTF - 16'?><nameHolder>"+params.paymentDetails.nameHolder+"</nameHolder>",
                                "<?xml version='1.0' encoding='UTF - 16'?><expiryDate>"+params.paymentDetails.expiry+"</expiryDate>",
                                "<?xml version='1.0' encoding='UTF - 16'?><cvc>"+params.paymentDetails.cvc+"</cvc>",
                                "<?xml version='1.0' encoding='UTF - 16'?><company>"+params.paymentDetails.issuer+"</company>"
                            ]
                        }
                    }
                }
            ]
        };
        emdInfo.push(paymentDetails);
    }
    return {
        "pos": {
            "source": [
                {
                    "pseudoCityCode": travelItineraryRS.ticketing[0].pseudoCityCode, "erspuserID": travelItineraryRS.ticketing[0].pricingSystem.code,
                    "terminalID": accountId
                }
            ]
        },
        "airBookModifyRQ": {
            "priceInfo": {
                "itinTotalFare": [
                    {
                        "baseFare": {
                            "amount": baseAmount
                        },
                        "taxes": {
                            "amount": taxesAmount
                        },
                        "totalFare": {
                            "amount": totalAmount
                        }, 
                        "fees": {
                            "amount": penality
                        }

                    }
                ]
            },
            "ticketing": tickets,
            "bookingReferenceID": [
                {
                    "id": travelItineraryRS.pnr,
                    "idcontext": travelItineraryRS.orderId ,
                    "flightRefNumberRPHList": [],
                    "instance": shoppingResponseRefID, 
                    "type": offerId
                }
            ],
            "emdinfo": emdInfo,
            "lastModified" : currentUser,
        },
        "airReservation": {
            "airItinerary": {
                "originDestinationOptions": {
                    "originDestinationOption": originDestinationOptions
                }
            },
            "ticketing": [],
            "bookingReferenceID": [
                {
                    "id": travelItineraryRS.pnr, 
                    "idcontext": travelItineraryRS.orderId 
                }
            ],
            "emdinfo": []
        }
    }
};

export default getBookModifyRQ;