import {
    LOADING,
    ON_FAIL_REISSUE,
    ON_FAIL_REISSUE_GENERAL,
    ON_FAIL_REISSUE_NONPAYMENT,
    ON_SUCCESS
} from "../types/reissueTypes";

const initialState = {
    loading: false,
    emissionRS: null,
    errorMsg: "",
    success: false
};

export default function(state = initialState, action) {
    switch (action.type) {
        case LOADING:
            return { ...state, loading: true, errorMsg: "" };
        case ON_SUCCESS:
            return {
                ...state,
                loading: false,
                emissionRS: action.payload,
                success: true
            };
        case ON_FAIL_REISSUE_GENERAL:
            return {
                ...state,
                loading: false,
                errorMsg: "Hemos recibido un error en la remisión, comunicarse con el equipo TI para ver el motivo.",
                success: false
            };
        case ON_FAIL_REISSUE:
            return {
                ...state,
                loading: false,
                errorMsg: "Error interno. Por favor comunicarse con el equipo de TI.",
                success: false
            };
        case ON_FAIL_REISSUE_NONPAYMENT:
            return {
                ...state,
                loading: false,
                errorMsg: action.errorMsg,
                success: false
            };

        default:
            return state;
    }
}
