import React, { Component } from "react";
import BaggageIndicator from "./baggage-widget";
import chevronDown from "./icons/chevron-down.png";
import departureIcon from "./icons/departure-icon.png";
import arrivalIcon from "./icons/arrival-icon.png";
import moment from "moment";
import "./Flight.scss";

class Flight extends Component {
    state = {
        toggle: false
    };

    toggleDetails = () => {
        this.setState(prevState => ({ toggle: !prevState.toggle }));
    };

    render = () => {
        //console.log("render Flight", this.props);
        const {
            id,
            airline,
            departureDateTime,
            arrivalDateTime,
            elapsedTime,
            segments,
            baggage,
            brandedFare,
            recommendationId,
            indicator,
            isSelected,
            onSelect
        } = this.props;
        const { toggle } = this.state;
        //console.log("Baggage Flight -->", baggage);
        const radioProps = {
            type: "radio",
            id: id,
            name: `flight-${recommendationId}-${indicator}`,
            onChange: () => onSelect(id)
        };

        if (isSelected) {
            radioProps["defaultChecked"] = true;
        }

        return (
            <div className={`flight pt-2 pb-2 ${toggle ? "border-bottom" : ""}`}>
                <label htmlFor={id} className="control control--radio flex mb-0 p-0 pt-2 pb-2 w-full bg-white border rounded-lg">

                        <div className="col-4 flex">
                            <img
                                className="flight--airline-logo mt-1 ml-2 mr-2"
                                src={`https://static.costamar.com.pe/web/airlines/${airline.code}_sq.png`}
                                alt="Airline logo"
                            />
                            <div className="flight--airline-name text-gray-800 font-secondary text-base truncate font-normal">
                                {airline.name}
                            </div>
                        </div>
                        <div className="col-3 pl-0">
                            <div className="flex text-gray-800 font-secondary font-normal">
                                <div className="">{departureDateTime.format("HH:mm a")}</div>
                                <span className="ml-1 mr-1"> – </span>
                                <div className="">{arrivalDateTime.format("HH:mm a")}</div>
                            </div>
                            <p className="text-xs text-gray-600 font-normal mb-0">
                                {`Duración: ${elapsedTime.substring(
                                    0,
                                    2
                                )} h ${elapsedTime.substring(2, 4)} min`}
                            </p>
                        </div>
                        <div className="col-2 pl-0">
                            <FlightStops segments={segments} />
                        </div>
                        <div className="col-2 pl-0">
                            <div className="float-left">
                                <BaggageIndicator baggage={baggage} />
                            </div>
                        </div>
                    <div className="col-1">
                        <div className="float-right">
                            <button
                                className="btn btn-white toggle-flight mr-3"
                                onClick={this.toggleDetails}
                            >
                                <img
                                    className={toggle ? "chevron-icon-up" : ""}
                                    src={chevronDown}
                                    alt="Chevron down"
                                />
                            </button>
                        </div>
                    </div>
                </label>
                {toggle ? (
                    <FlightDetails brandedFare={brandedFare} segments={segments} />
                ) : null}
            </div>
        );
    };
}

const getDifference = (start, end, format) => {
    let difference = moment(end).diff(moment(start));
    let interval = moment(difference).utc();

    return interval.format(format);
};

const waitingTime = segments => {
    const segmentsText = [];
    segments.forEach((s, i) => {
        let waitingTime = "";
        if (i > 0 && i < segments.length) {
            waitingTime = getDifference(
                segments[i - 1].arrivalDateTime,
                s.departureDateTime,
                "HH [h] mm [min]"
            );
            if (segments.length > 2) {
                segmentsText.push(s.departureAirport.code);
            } else {
                segmentsText.push(`${waitingTime} en ${s.departureAirport.code}`);
            }
        }
    });

    return segments.length > 1 ? <span>{segmentsText.join(", ")}</span> : null;
};

const FlightStops = ({ segments }) => {
    const description = length => {
        if (length > 1) {
            return `${length - 1} escala${length - 1 > 1 ? "s" : ""}`;
        }
        return "Directo";
    };

    return (
        <div>
            <p className="flex font-secondary font-normal mb-0">
                {description(segments.length)}
            </p>
            <p className="text-xs text-gray-600 font-normal mb-0">
                <span>{waitingTime(segments)}</span>
            </p>
        </div>
    );
};

const FlightDetails = ({ brandedFare, segments }) => {
    return (
        <div className={`flight-details`}>
            <div className="row">
                <div className="col">
                    <div className="flight-details-header pt-2 pb-2 pl-3 pr-3 border-bottom bg-white">
                        <h3 className="font-secondary text-xl mt-2 mb-2">
                            Detalle de vuelo
                            <span className="fare-badge text-secondary pl-2 pr-2 text-sm ml-3">
                {brandedFare.description || brandedFare.brandID}
              </span>
                        </h3>
                    </div>
                    <div className="flight-details-content pl-3 pr-3">
                        {segments.map((segment, i) => {
                            const stop = {};

                            if (segments.length > 1 && i < segments.length - 1) {
                                const waitingTime = getDifference(
                                    segment.arrivalDateTime,
                                    segments[i + 1].departureDateTime,
                                    "HH [h] mm [min]"
                                );
                                stop["description"] = `Escala de ${waitingTime}`;
                            }

                            return <FlightSegment {...segment} stop={stop} />;
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

const FlightSegment = ({
                           departureAirport,
                           departureDateTime,
                           arrivalAirport,
                           arrivalDateTime,
                           marketingAirline,
                           operatingAirline,
                           flightNumber,
                           bookingClass,
                           stop
                       }) => {
    return (
        <div className="flight-segment p-3">
            <div className="row">
                <div className="col-2 pr-0">
                    <img
                        className="airline-logo"
                        src={`https://static.costamar.com.pe/web/airlines/${marketingAirline.code}.png`}
                        alt="Airline logo"
                    />
                </div>
                <div className="col-10">
                    <p className="font-secondary">
                        <img
                            src={departureIcon}
                            className="departure-arrival-icon mr-2"
                            alt="Departure icon"
                        />
                        <span>
              {departureDateTime.format("DD MMM YYYY")}
                            <span className="ml-2 mr-2">·</span>
                            {departureDateTime.format("HH:mm a")}
                            <span className="ml-2 mr-2">·</span>
                            {departureAirport.name}
            </span>
                    </p>
                    <div className="flight-segment-dots" />
                    <p className="font-secondary">
                        <img
                            src={arrivalIcon}
                            className="departure-arrival-icon mr-2"
                            alt="Departure icon"
                        />
                        <span>
              {arrivalDateTime.format("DD MMM YYYY")}
                            <span className="ml-2 mr-2">·</span>
                            {arrivalDateTime.format("HH:mm a")}
                            <span className="ml-2 mr-2">·</span>
                            {arrivalAirport.name}
            </span>
                    </p>
                    <p className="mb-0 text-xs text-gray-600">
                        {marketingAirline.name}
                        <span className="ml-2 mr-2">·</span>
                        Vuelo número {flightNumber}
                        <span className="ml-2 mr-2">·</span>
                        Clase {bookingClass}
                    </p>
                    <p className="mb-0 text-xs text-gray-600">
                        Este vuelo es operado por {operatingAirline.name}
                    </p>
                </div>
            </div>
            {stop && stop.description ? (
                <div className="row mt-3">
                    <div className="col-2"></div>
                    <div className="col-10">
                        <div className="border-top border-bottom pt-1 pb-2">
                            <span className="text-xs text-gray-600">{stop.description}</span>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default Flight;