import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as bookingInformationActions from '../../../actions/bookingInformationActions'
import './bookingInformationForm.scss';
import Loading from "../../common/Loading";
import Alert from "../../common/Alert";
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons/faSearch";

class Index extends Component {

    onChangePNR = (e) => {
        this.props.changePNR(e.target.value);
    };

    onChangeGDS = (e) => {
        this.props.changeGDS(e.target.value);
    }

    onSearchBookingInformation = () => {
        const { pnr, gds } = this.props;
        if (gds !== "GDS") {
            const data = {
                pnr: pnr.trim(),
                gds
            }
            this.props.searchBookingInformation(data);
        } else {
            alert("Debe elegir un GDS válido");
        }
        
    };

    showActions = () => {
        if ((!this.props.pnr || !this.props.gds) || this.props.loading) {
            return (<button type="submit" className="btn btn-primary mb-2 btn-lg p-3 px-4 disabled" disabled="disabled"><FontAwesomeIcon icon={faSearch} /> </button>)
        }
        return (
            <button type="submit" className="btn btn-primary mb-2 btn-lg p-3 px-4" onClick={this.onSearchBookingInformation}><FontAwesomeIcon icon={faSearch} /> </button>
        )
    };

    render () {
        //console.log(this.props);
        this.props.changeGDS("NDC");
        const { loading, errorMsg, isComplete } = this.props;
        let paddingStyle = (!isComplete) ?'18%':'';
        return (
            <div className="BookingInformationForm" style={{"marginTop": paddingStyle}}>
                <h4 className="subtitle">Ingrese los datos de su reserva</h4>
                <div className="BookingInformation-form">
                    <div className="row">
                        <div className='col-md-4 col-md-4 offset-md-2'>
                            <input type="text"
                                className="form-control form-control-lg"
                                placeholder="Código de reserva"
                                value={this.props.pnr}
                                onChange={this.onChangePNR} />
                        </div>

                        {/* <div className='col-md-4'>
                            <input type="text"
                                className="form-control form-control-lg"
                                placeholder="Apellido" />
                        </div> */}
                        <div className='col-md-3'>
                            <select
                                className="form-control form-control-lg"
                                onChange={this.onChangeGDS}
                                placeholder='GDS'
                            >
                                {/* <option>GDS</option> */}
                                <option value="NDC">NDC</option>
                                {/* <option value="SA">SABRE</option> */}
                            </select>
                        </div>
                        <div className="col-md-2">
                            {
                                this.showActions()
                            }
                        </div>
                    </div>
                </div>
                {
                    loading ? <Loading message="Recuperando información de reserva."/> : null
                }
                {
                    errorMsg ? <Alert message={errorMsg}/> : null
                }
            </div>
        )
    }

}

const mapStateToProps = ({bookingInformationReducer}) => bookingInformationReducer;

export default connect(mapStateToProps, bookingInformationActions)(Index);
