import React, {Component} from "react";
import {connect} from "react-redux";
import moment from "moment";
import * as recommendationsActions from "../../../../actions/recommendationsActions";
import LocationInput from "@bit/codeorangex.minerva-ui.ui.location-input";
import CalendarInput from "@bit/codeorangex.minerva-ui.ui.calendar-input";
import SelectInput from "@bit/codeorangex.minerva-ui.ui.select-input";
import "moment/locale/es";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";

moment().locale("es");

class FlightEngine extends Component {
    constructor(props) {
        super(props);

        const { travelItineraryRS : { airItinerary : {
            originDestinationOptions }}} = this.props.bookingInformationReducer;
        const originFS = originDestinationOptions.originDestinationOption[0].flightSegment[0];
        let returnFS = "";
        let endDate = "";
        let destination = "";
        let flighTypeOptionDefault = "RT"
        if(originDestinationOptions.originDestinationOption.length>1){
            returnFS = originDestinationOptions.originDestinationOption[1].flightSegment[0];
            endDate = moment(returnFS.departureDateTime);
            destination = returnFS.departureAirport.locationCode;
        }else{
            destination = originDestinationOptions.originDestinationOption[0].flightSegment[originDestinationOptions.originDestinationOption[0].flightSegment.length-1].arrivalAirport.locationCode;
            flighTypeOptionDefault = "OW"
        }



        this.state = {
            origin: originFS.departureAirport.locationCode,
            destination: destination,
            startDate: moment(originFS.departureDateTime),
            endDate:endDate,
            flighTypeOptionDefault: flighTypeOptionDefault
        };

        this.onChangeFlightType(flighTypeOptionDefault);
    }

    onChangeParams = (name, value) => {
        this.setState({
            [name]: value
        });
    };
    onChangeFlightType = (flightType) => {
        this.props.onChangeFlightTypeOptions(flightType);
    };

    onSearchRecommendation = () => {
        const params = {
            origin: this.state.origin,
            destination: this.state.destination,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            flightTypeOption: this.props.recommendationsReducer.flightTypeOption,
            email: this.props.recommendationsReducer.email,
            ruc: this.props.recommendationsReducer.ruc,
            hasRUC: this.props.recommendationsReducer.hasRUC,
            hasFEE: this.props.recommendationsReducer.hasFEE,
            feeAmount: this.props.recommendationsReducer.feeAmount
        };
        this.props.searchRecommendations(params);
    };

    render() {
        const flightTypeOptions = [
            {key: "RT", value: "Ida y vuelta"},
            {key: "OW", value: "Solo ida"},
            {key: "OR", value: "Solo Regreso"}
        ];
        let {
            origin,
            destination,
            startDate,
            endDate,
            flighTypeOptionDefault
        } = this.state;

        let { flightTypeOption } = this.props.recommendationsReducer;
        let startDateData = {
            label: "Desde...",
            value: startDate
        };
        let originDestination = {
            origin: origin,
            destination: destination
        };
        let endDateClass = "DateInputs";
        if (flightTypeOption !== "RT") {
            startDateData.label = "Para cuando ?";
            endDateClass += " hidden";
            if (flightTypeOption === "OR") {
                //startDateData.value = endDate;
                originDestination.origin = destination;
                originDestination.destination = origin;
            }
        }

        return (
            <div className="row">
                <div className="col-12">
                    <div className="container-box">
                        <div className="FlightEngine">
                            <div className="FlightEngine-header mb-1">
                                <div className="">
                                    <SelectInput
                                        label={"Seleccione"}
                                        options={flightTypeOptions}
                                        selectedKey={flighTypeOptionDefault}
                                        onChange={option =>
                                            this.onChangeFlightType(option.key)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="FlightEngine-engine engine-content">
                                <div className="FlightEngine-form">
                                    <div className="AirportInput">
                                        <LocationInput
                                            id="origin"
                                            label="Desde donde?"
                                            placeholder="Ingrese una ciudad"
                                            location={{
                                                code: originDestination.origin,
                                                name: originDestination.origin,
                                                cityName: originDestination.origin
                                            }}
                                            onChange={option =>
                                                this.onChangeParams("origin", option.cityCode)
                                            }
                                        />
                                    </div>
                                    <div className="AirportInput">
                                        <LocationInput
                                            id="destination"
                                            label="A donde?"
                                            placeholder="Ingrese una ciudad"
                                            location={{
                                                code: originDestination.destination,
                                                name: originDestination.destination,
                                                cityName: originDestination.destination
                                            }}
                                            onChange={option =>
                                                this.onChangeParams("destination", option.cityCode)
                                            }
                                        />
                                    </div>
                                    <div className="DateInputs">
                                        <CalendarInput
                                            id={`calendar-simple`}
                                            label={startDateData.label}
                                            format="ddd DD MMM"
                                            date={startDateData.value}
                                            minDate={moment()}
                                            onChange={({date}) =>
                                                this.onChangeParams("startDate", date)
                                            }
                                        />
                                    </div>
                                    <div className={endDateClass}>
                                        <CalendarInput
                                            id={`end-date`}
                                            label="Hasta..."
                                            format="ddd DD MMM"
                                            minDate={moment()}
                                            date={moment(endDate)}
                                            onChange={({date}) =>
                                                this.onChangeParams("endDate", date)
                                            }
                                        />
                                    </div>
                                    <div className="engine-submit">
                                        <button
                                            className="btn btn-primary "
                                            type="submit"
                                            onClick={this.onSearchRecommendation}
                                        >
                                            <FontAwesomeIcon icon={faSearch} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div style={{clear: "both"}}> </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps({
                             recommendationsReducer,
                             bookingInformationReducer
                         }) {
    return {
        recommendationsReducer,
        bookingInformationReducer
    };
}

export default connect(mapStateToProps, recommendationsActions)(FlightEngine);
