import React, { Component } from "react";
import BookingInformationForm from "./BookingInformationForm";
import { connect } from "react-redux";
import BookingInformation from "./BookingInformation";
import SearchRecommendations from "./SearchRecommendations";

import * as bookingInformationActions from '../../actions/bookingInformationActions';
import Loading from "../common/Loading";
import Alert from "../common/Alert";
import config from '../../config';

class HomePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            params: {}
        }
    }

    componentDidMount() {
        this.onSearchBookingInformation();
        this.setState({ loading: false });
    }

    onSearchBookingInformation = () => {
        const { pnr, gds, email,currentUser, signature, orderId, accountId } = this.decryptParams(this.props.match.params);
            const data = {
                pnr,
                gds,
                email,
                currentUser,
                signature,
                orderId,
                accountId
            }
            const session = {email: this.props.match.params.email, signature: this.props.match.params.signature, currentUser: this.props.match.params.currentUser, accountId: this.props.match.params.accountId};
            sessionStorage.setItem(config.app, JSON.stringify(session));
            this.setState({ params: data }, () => this.props.searchBookingInformation(data) );
    };

    decryptParams = (params) => {
        const pnr           = new Buffer(params.pnr, 'base64').toString('ascii');
        const gds           = new Buffer(params.gds, 'base64').toString('ascii');
        const email         = new Buffer(params.email, 'base64').toString('ascii');
        const currentUser   = new Buffer(params.currentUser, 'base64').toString('ascii');
        const signature     = new Buffer(params.signature, 'base64').toString('ascii');
        const orderId       = new Buffer(params.orderId, 'base64').toString('ascii');
        const accountId     = new Buffer(params.accountId, 'base64').toString('ascii');
        return {
            pnr,
            gds,
            email,
            currentUser,
            signature,
            orderId,
            accountId
        }
    }

    renderBody = () => {
        const { loading, isComplete, errorMsg } = this.props;
        return (
            <div>
                {
                    loading ?
                        <Loading message="Recuperando información de reserva."/>
                        :
                        isComplete ?
                            (<div>
                                { errorMsg ? <Alert message={errorMsg}/> :
                                    <div>
                                        <BookingInformation />
                                        <SearchRecommendations history={this.props.history} params={this.state.params} />
                                    </div>
                                }
                            </div>
                            ) : "No se encontró información del PNR o error de comunicación con el servidor"
                }

            </div>
        )
    };

    render() {
        return (
            <div className="Home row">
                <div className="container">
                    {this.renderBody()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ bookingInformationReducer }) => bookingInformationReducer;

export default connect(mapStateToProps, bookingInformationActions)(HomePage);
