import axios from 'axios';
import {CHANGE_GDS, CHANGE_PNR, LOADING, ON_FAIL_BOOKING_INFO, ON_RECEIVE_BOOKING_INFO, ON_FAIL_PROVIDER_BOOKING_INFO, ON_VALID_BOOKING_INFO} from "../types/bookingInformationTypes";
import { ON_RECEIVE_ACCOUNT_INFORMATION, ON_FAIL_ACCOUNT_INFORMATION, LOADING as LOADING_INFO } from '../types/accountInformationTypes';
import getBookingInformationRQ from "../transfer/bookingInformatonTransfer/getBookingInformationRQ";
import getTravelItinerarySimplified from "../transfer/bookingInformatonTransfer/getTravelItinerarySimplified";
import config from "../config";

const getOTAProfileReadRQ = require('../transfer/accountInformationTransfer/getOTAProfileReadRQ');
const getOTAProfileReadRS = require("../transfer/accountInformationTransfer/getOTAProfileReadRS");

export const changePNR = (pnr) => dispatch => {
    dispatch({
        type: CHANGE_PNR,
        payload: pnr
    });
};

export const changeGDS = (gds) => dispatch => {
  dispatch({
    type: CHANGE_GDS,
    payload: gds
  })
}

export const searchBookingInformation = (data) => async (dispatch, getState) => {
    dispatch({
        type: LOADING
    });

    try {
        const rq = getBookingInformationRQ(data);
        const res = await axios.post(config.services.reissue.travelItineraryRead, rq);

        if (res?.data?.errors?.error?.length > 0) {
          let errorMsg = res.data?.errors?.error?.[0]?.value || res.data?.errors?.error?.[0]?.shortText || "";
          dispatch({
            type: ON_VALID_BOOKING_INFO,
            errorMsg,
          });
        } else {
          const info = res.data?.travelItinerary;
          const requiredProps = ["accountId", "currentUser", "email", "orderId", "signature"];
          const allPropsExist = requiredProps.every(prop => data.hasOwnProperty(prop) && data[prop] != null);
          const hasValidationInfo = (allPropsExist) ? (rq.uniqueID?.id === info.itineraryRef.id && rq.pos?.source[0].terminalID === info.customerInfos?.customerInfo[0].profileRef?.id) : true;
          if (hasValidationInfo)  {
            if (res.data.travelItinerary != null) {
              
              if(!allPropsExist){
                sessionStorage.setItem(config.app, JSON.stringify({accountId:  Buffer.from(info.customerInfos.customerInfo[0].profileRef.id, 'ascii').toString('base64')}));
              }

              dispatch({
                type: ON_RECEIVE_BOOKING_INFO,
                payload: getTravelItinerarySimplified(res.data)
              });
            } else {
              dispatch({ type: ON_FAIL_PROVIDER_BOOKING_INFO, payload: { errorCode: 1001 } });
            }
          } else {
            dispatch({
              type: ON_VALID_BOOKING_INFO,
              errorMsg: "La reserva no pertence a su cuenta, gracias.",
            });
          }
        }
    }
    catch(ex){
        console.log("FAIL BOOKING INFORMATION", ex);
        dispatch({ type: ON_FAIL_BOOKING_INFO, payload: { errorCode: 1001 } });
    }
};

export const accountInformation = params => async (dispatch, getState) => {
  dispatch({
    type: LOADING_INFO
  });
  try {
    const { travelItineraryRS } = getState().bookingInformationReducer;
    const res = await axios.post(config.services.reissue.accountsInformation, getOTAProfileReadRQ(params));
    if (res.data) {
      dispatch({
        type: ON_RECEIVE_ACCOUNT_INFORMATION,
        payload: getOTAProfileReadRS(res.data)
      });
    } else {
      dispatch({
        type: ON_FAIL_ACCOUNT_INFORMATION,
        payload: { errorCode: 1001 }
      });
    }
  } catch (ex) {
    console.log(ex);
    dispatch({
      type: ON_FAIL_ACCOUNT_INFORMATION,
      payload: { errorCode: 1001 }
    });
  }
};