const config = {
    services: {
      reissue: {
        //DEV
        //travelItineraryRead: 'http://localhost:8181/v1/air/travelItineraryRead',
        //price: 'http://localhost:8181/v1/air/price',
        //modify: 'http://localhost:8181/v1/air/modify',
        //downloadTicket: 'http://localhost:8181/v1/air/downloadTicket',
        //transaction: 'http://localhost:8181/v1/air/transaction',
        //accountsInformation: 'http://localhost:8181/v1/accounts/information',
        //PROD
        travelItineraryRead: 'https://reissue-service.zdev.tech/zreissue-service/v1/air/travelItineraryRead',
        price: 'https://reissue-service.zdev.tech/zreissue-service/v1/air/price',
        modify: 'https://reissue-service.zdev.tech/zreissue-service/v1/air/modify',
        downloadTicket: 'https://reissue-service.zdev.tech/zreissue-service/v1/air/downloadTicket',
        transaction: 'https://reissue-service.zdev.tech/zreissue-service/v1/air/transaction',
        accountsInformation: 'https://reissue-service.zdev.tech/zreissue-service/v1/accounts/information',
      }
    },
    app: 'reissues_data'
};

export default config;