import {LOADING, ON_FAIL_REISSUE, ON_SUCCESS,ON_FAIL_REISSUE_NONPAYMENT, ON_FAIL_REISSUE_GENERAL} from "../types/reissueTypes";
import {LOADING_TRANSACTION, ON_RECEIBE_CONFIRMATION} from "../types/transactionTypes";
import axios from "axios";
import getBookModifyRQ from "../transfer/reissueTransfer/getBookModifyRQ";
import config from "../config";

export const reissueTicket = params => async (dispatch, getState) => {
    dispatch({
        type: LOADING
    });
    try {
        const { travelItineraryRS } = getState().bookingInformationReducer;
        const { recommendations , flightTypeOption} = getState().recommendationsReducer;
        
        const RQ = getBookModifyRQ(
            params,travelItineraryRS,
            recommendations[params.recommendationId],
            flightTypeOption);
        console.log("RQ - ReissueTicketing -->",RQ);
        const res = await axios.post(config.services.reissue.modify, RQ);
        console.log("RS - ReissueTicketing -->",res);

        if (res.data) {
            if(res.data.errors){
                let errorMsg = res.data?.errors?.error?.[0]?.value || res.data?.errors?.error?.[0]?.shortText || "";
                let errorCode = res.data?.transactionStatusCode || "";

                switch(errorCode) {
                    case "RLNC03":
                    case "RLNC04":
                        dispatch({
                            type: ON_FAIL_REISSUE_NONPAYMENT,
                            payload: { errorCode },
                            errorMsg
                        });
                    break;
                    case "1003":
                        dispatch({
                            type: ON_FAIL_REISSUE_NONPAYMENT,
                            payload: { errorCode },
                            errorMsg: "La remisión no se pudo realizar. Por favor, inténtelo nuevamente en unos minutos."
                        });
                    break;
                    default:
                        dispatch({
                            type: ON_FAIL_REISSUE_GENERAL,
                            payload: { errorCode }
                        });
                    break;
                }
            } else {
                let ticketList = [];
                try {
                    const downloadRequest = {
                        orderId: RQ.airBookModifyRQ.bookingReferenceID[0].idcontext,
                        recordLocator: RQ.airBookModifyRQ.bookingReferenceID[0].id,
                        pseudoCityCode: RQ.pos.source[0].pseudoCityCode,
                        gds: RQ.pos.source[0].erspuserID
                    };
                    ticketList = await downloadTicket(downloadRequest);
                } catch (err) {
                    console.error("Error al descargar tickets:", err);
                }

                if (res.data?.successAndWarningsAndAirReservation?.[0]?.ticketing) {
                    const enrichedTicketing = res.data.successAndWarningsAndAirReservation[0].ticketing.map(ticket => {
                        const matchedPdf = ticketList.find(t => t.ticket === ticket.ticketDocumentNbr);
                        return {
                            ...ticket,
                            document: matchedPdf ? matchedPdf.document : null
                        };
                    });
                
                    res.data.successAndWarningsAndAirReservation[0].ticketing = enrichedTicketing;
                }
                dispatch({
                    type: ON_SUCCESS,
                    payload: res.data
                });
            }
        } else {
            dispatch({
                type: ON_FAIL_REISSUE,
                payload: { errorCode: 1001 }
            });
        }
    } catch (ex) {
        console.log(ex);
        dispatch({
            type: ON_FAIL_REISSUE,
            payload: { errorCode: 1001 }
        });
    }
};

export const addConcurrence = (pnr) => async (dispatch, getState) => {
    //console.log("PNR add -->", pnr);
    dispatch({
        type: LOADING_TRANSACTION
    });
    try {
        const res = await axios.get(URL + "transaction/" + pnr);

        if (res.data) {
            dispatch({
                type: ON_RECEIBE_CONFIRMATION,
                payload: res.data
            });
        }
    } catch (ex) {
        console.log(ex);
    }
};

export const downloadTicket = async (requestBody) => {
    try {
        console.log("RQ - downloadTicket-->", requestBody);
        const resDownloadTicket = await axios.post(config.services.reissue.downloadTicket, requestBody);
        console.log("RS - downloadTicket-->", resDownloadTicket);
        return resDownloadTicket.data;
    } catch (ex) {
        console.log(ex);
        throw ex;
    }
};