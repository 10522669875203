import React from 'react';

const PassengerTicket = (props) => {
    const handleIndividualDownload = () => {
        if (!props.ticket.document) return;
    
        const byteCharacters = atob(props.ticket.document);
        const byteArray = new Uint8Array(byteCharacters.split('').map(c => c.charCodeAt(0)));
        const blob = new Blob([byteArray], { type: 'application/pdf' });
    
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `Ticket-${props.ticket.ticketDocumentNbr}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    return (
        <tr>
            <td>{props.ticket.travelerRefNumber}</td>
            <td>{props.ticket.ticketDocumentNbr}</td>
            <td className="pb-0 pt-2">
                {props.ticket.document ? (
                <button className="btn btn-sm btn-outline-info" onClick={handleIndividualDownload}>
                    Descargar ticket
                </button>
                ) : (
                <span className="text-muted">No disponible</span>
                )}
            </td>
        </tr>
    );
};

export default PassengerTicket;