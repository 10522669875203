import axios from "axios";
import {
  LOADING,
  ON_RECEIVE_ACCOUNT_INFORMATION,
  ON_FAIL_ACCOUNT_INFORMATION
} from "../types/accountInformationTypes";
import config from "../config";
const getOTAProfileReadRS = require('../transfer/accountInformationTransfer/getOTAProfileReadRQ');
const getOTAProfileReadRQ = require("../transfer/accountInformationTransfer/getOTAProfileReadRS");


export const accountInformation = params => async (dispatch, getState) => {
  dispatch({
    type: LOADING
  });
  try {
    const { otaProfileRS } = getState().accountInformationReducer;

    const res = await axios.post(config.services.reissue.accountInformation, getOTAProfileReadRQ(params,otaProfileRS));
    //console.log("AccountID RS ->", res.data);
    if (res.data) {
      dispatch({
        type: ON_RECEIVE_ACCOUNT_INFORMATION,
        payload: getOTAProfileReadRS(res.data)
      });
    } else {
      dispatch({
        type: ON_FAIL_ACCOUNT_INFORMATION,
        payload: { errorCode: 1001 }
      });
    }
  } catch (ex) {
    console.log(ex);
    dispatch({
      type: ON_FAIL_ACCOUNT_INFORMATION,
      payload: { errorCode: 1001 }
    });
  }
};

